module.exports = [{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-remark-images/3.1.25_82878940f19774a3cf3db21c1ffc6ad1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-gtag/4.8.0_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-959960671"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-analytics/2.1.20_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-54802801-1"},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-facebook-pixel/1.0.3/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1017174638461428"},
    },{
      plugin: require('/solution/ANEX.Website.Osu/TOM/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
